import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';

import { ErrorBoundary } from 'components/error-boundary';
import { Text } from 'components/formatted-message';
import { Header } from 'components/header/header';
import { Sidebar } from 'components/sidebar/sidebar';
import { makeStyles } from 'utils/make-styles';

import { DRAWER_WIDTH } from './constants';

const HEADER_HEIGHT = 60;
const DRAWER_COLLAPSED_WIDTH = 40;

const useStyles = makeStyles()(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100vh',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
    header: {
      height: HEADER_HEIGHT,
      borderBottom: theme.border.base,
      padding: theme.spacing(2, 4),
      background: theme.palette.background.default,
      flexShrink: 0,
      zIndex: 1,
    },
    content: {
      overflowY: 'scroll',
      padding: theme.spacing(6, 2),
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      borderLeft: theme.palette.table.border,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.background.darkGrey,
      marginLeft: 40,
      '&.isMobile': {
        marginLeft: 0,
      },
      '&.isOpen': {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: DRAWER_WIDTH,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(6, 4),
      },
    },
    drawer: {
      flexShrink: 0,
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      top: HEADER_HEIGHT,
      position: 'absolute',

      '&.isMobile': {
        top: 0,
        height: '100vh',
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      height: `calc(100vh - ${HEADER_HEIGHT * 2}px)`,
      paddingTop: theme.spacing(3),
      position: 'relative',
      border: 'none',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      '&.isMobile': {
        height: '100vh',
        top: 0,
      },
      '&.isClosed': {
        width: DRAWER_COLLAPSED_WIDTH,
      },
    },
    toggleBlock: {
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      left: 0,
      width: DRAWER_WIDTH,
      padding: theme.spacing(4),

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),

      '&.isClosed': {
        width: DRAWER_COLLAPSED_WIDTH,
        padding: theme.spacing(4, 2),
        alignItems: 'center',
      },
    },
    toggle: {
      cursor: 'pointer',
      color: theme.palette.text.secondary,

      '&.isOpen': {
        transform: 'rotate(180deg)',
      },
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    appVersion: {
      margin: 'auto 0',

      '&.isClosed': {
        display: 'none',
      },
    },
  }),
  { name: 'bo-BaseLayout' }
);

export function BaseLayout({ children }) {
  const router = useRouter();
  const { classes, theme, cx } = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isOpen, toggleIsOpen] = useToggle(isDesktop);

  useEffect(() => toggleIsOpen(isDesktop), [isDesktop, toggleIsOpen]);

  useUpdateEffect(() => {
    if (!isDesktop) {
      toggleIsOpen(false);
    }
  }, [router.asPath]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header isDesktop={isDesktop} toggleIsOpen={toggleIsOpen} />
      </div>

      <main
        className={cx(classes.content, isDesktop && isOpen && 'isOpen', !isDesktop && 'isMobile')}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>

      <Drawer
        anchor="left"
        className={cx(classes.drawer, !isDesktop && 'isMobile')}
        classes={{
          paper: cx(classes.drawerPaper, !isDesktop && 'isMobile', !isOpen && 'isClosed'),
        }}
        open={isOpen}
        variant={isDesktop ? 'permanent' : undefined}
        onClose={toggleIsOpen}>
        <Sidebar isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
      </Drawer>
      {isDesktop && (
        <Box className={cx(classes.toggleBlock, !isOpen && 'isClosed')}>
          <DoubleArrowIcon
            className={cx(classes.toggle, isOpen && 'isOpen', !isOpen && 'isClosed')}
            onClick={toggleIsOpen}
          />
          <Text
            className={cx(classes.appVersion, !isOpen && 'isClosed')}
            color="textSecondary"
            id="application.version"
            values={{ version: process.env.APP_VERSION }}
            variant="caption"
          />
        </Box>
      )}
    </div>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export const getLayout = page => <BaseLayout>{page}</BaseLayout>;
