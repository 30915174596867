import { pipe } from './fp.utils';

export function snakeToKebab(string) {
  if (!string) return string;
  return string.replace(/_/g, '-');
}

export function capitalizeFirst(string) {
  if (!string) return string;
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function capitalizeAll(string) {
  if (!string) return string;
  return string.split(' ').map(capitalizeFirst).join(' ');
}

export function snakeToSentence(string) {
  if (!string) return string;
  return string.replaceAll('_', ' ');
}

export function capitalizeSnakeSentence(string) {
  return pipe(snakeToSentence, capitalizeFirst)(string);
}

export function toSnakeCase(string) {
  if (!string) return string;

  return string
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => `_${y.toLowerCase()}`)
    .replace(/^_/, '') // Convert camel case to snake case
    .replace(/-/g, '_') // Convert dash case to snake case
    .replace(/\s+/g, '_'); // Convert space case to snake case
}
