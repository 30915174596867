import MuiAutocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { makeStyles } from 'utils/make-styles';
import { capitalizeFirst } from 'utils/string';

import { AutocompleteInput } from './autocomplete-input';

function defaultRenderOption(optionProps, option) {
  return <li {...optionProps}>{option.label}</li>;
}

function defaultRenderInput(props) {
  return <AutocompleteInput {...props} />;
}

const useStyles = makeStyles()(
  {
    root: {
      [`&:not(.Mui-focused) .${autocompleteClasses.inputRoot}`]: {
        flexWrap: 'nowrap',
      },
    },
  },
  { name: 'bo-Autocomplete' }
);

const ALL_PLACEHOLDER_ID = 'field.common.label.all';
const EMPTY_VALUE = 'empty';

const defaultRenderLabel = label => (typeof label === 'string' ? capitalizeFirst(label) : label);

export const Autocomplete = forwardRef(
  (
    {
      options: propsOptions,
      value: propsValue,
      labelKey,
      valueKey,
      className,
      multiple,
      inputProps,
      'data-test-id': dataTestId,
      withAllOption,
      freeSolo,
      renderOption,
      renderInput,
      onChange,
      renderLabel,
      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles();
    const { formatMessage } = useIntl();

    const handleChange = useCallback(
      (event, selected, reason) => {
        if (multiple) {
          const selectedValue = selected.map(option => option?.value ?? option);
          const isEmptyValue = selectedValue.includes(EMPTY_VALUE);

          onChange?.(isEmptyValue ? [] : selectedValue, event, reason);
        } else {
          const selectedValue = selected?.value ?? null;
          const isEmptyValue = selectedValue === EMPTY_VALUE;

          onChange?.(isEmptyValue ? null : selectedValue, event, reason);
        }
      },
      [multiple, onChange]
    );

    const options = useMemo(() => {
      const mappedOptions = propsOptions.map(option => {
        if (typeof option === 'object') {
          const label = option[labelKey] ?? formatMessage({ id: option.labelId });

          return {
            ...option,
            value: option[valueKey],
            label: renderLabel(label),
          };
        }

        return {
          value: option,
          label: renderLabel(option),
        };
      });

      const emptyValueLabel = formatMessage({ id: ALL_PLACEHOLDER_ID });

      return withAllOption && mappedOptions?.length > 0
        ? [
            {
              value: EMPTY_VALUE,
              label: emptyValueLabel,
            },
            ...mappedOptions,
          ]
        : mappedOptions;
    }, [formatMessage, labelKey, propsOptions, renderLabel, valueKey, withAllOption]);

    const value = useMemo(() => {
      if (isEmpty(options)) return propsValue ?? null;

      if (freeSolo) return propsValue ?? null;

      return Array.isArray(propsValue)
        ? options.filter(option => propsValue.includes(option.value))
        : options.find(option => option.value === propsValue) ?? null;
    }, [propsValue, options, freeSolo]);

    const renderOptionCallback = useCallback(
      (optionProps, option) => {
        const optionTestId = `${dataTestId}.option.${optionProps.key
          .toLowerCase()
          .replace(/\s/g, '.')}`;

        if (option.value === EMPTY_VALUE) {
          return defaultRenderOption(
            {
              ...optionProps,
              'data-test-id': `${dataTestId}.option.all`,
              'aria-selected': isEmpty(value),
            },
            option
          );
        }

        return renderOption({ ...optionProps, 'data-test-id': optionTestId }, option);
      },
      [dataTestId, renderOption, value]
    );

    const renderInputCallback = useCallback(
      params =>
        renderInput({
          ...params,
          'data-test-id': `${dataTestId}.input`,
          ...inputProps,
          placeholderId:
            isEmpty(value) && withAllOption && !inputProps.placeholderId
              ? ALL_PLACEHOLDER_ID
              : inputProps.placeholderId,
          isEmptyValue: isEmpty(value),
          InputProps: { ...params.InputProps, ...inputProps.InputProps },
        }),
      [dataTestId, inputProps, renderInput, value, withAllOption]
    );

    return (
      <MuiAutocomplete
        ref={ref}
        className={cx(classes.root, className)}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        options={options}
        size="small"
        value={value}
        {...props}
        renderInput={renderInputCallback}
        renderOption={renderOptionCallback}
        onChange={handleChange}
      />
    );
  }
);

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  multiple: PropTypes.bool,
  inputProps: PropTypes.shape(),
  'data-test-id': PropTypes.string.isRequired,
  withAllOption: PropTypes.bool,
  freeSolo: PropTypes.bool,
  className: PropTypes.string,
  renderOption: PropTypes.func,
  renderInput: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  renderLabel: PropTypes.func,
};

Autocomplete.defaultProps = {
  options: [],
  value: undefined,
  labelKey: 'label',
  valueKey: 'value',
  multiple: false,
  inputProps: {},
  withAllOption: false,
  freeSolo: false,
  className: undefined,
  renderOption: defaultRenderOption,
  renderInput: defaultRenderInput,
  renderLabel: defaultRenderLabel,
};
