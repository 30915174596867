import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { Tooltip } from 'components/tooltip';
import { makeStyles } from 'utils/make-styles';

const useStyles = makeStyles()(
  (theme, { flagUrl }) => ({
    country: {
      width: 16,
      height: 16,
      flexShrink: 0,
      backgroundColor: theme.palette.secondary.light,
      backgroundSize: 'contain',
      backgroundImage: `url(${flagUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderRadius: '50%',
    },
  }),
  { name: 'bo-Country' }
);

export function FlagUI({ flagUrl, name }) {
  const { classes } = useStyles({ flagUrl });

  if (!name) return <Box className={classes.country} />;

  return (
    <Tooltip title={name}>
      <Box className={classes.country} />
    </Tooltip>
  );
}

FlagUI.propTypes = {
  flagUrl: PropTypes.string.isRequired,
  name: PropTypes.string,
};

FlagUI.defaultProps = {
  name: undefined,
};

export const Flag = memo(FlagUI);
