import { useCallback, useState } from 'react';

export function useMenuState() {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = useCallback(
    event => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const closeMenu = useCallback(event => {
    event?.stopPropagation();
    setAnchorEl(null);
  }, []);

  return { anchorEl, isMenuOpen: Boolean(anchorEl), openMenu, closeMenu };
}
