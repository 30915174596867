import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { alpha, useMediaQuery } from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { t } from 'components/formatted-message';
import { makeStyles } from 'utils/make-styles';

import { DRAWER_WIDTH } from '../../layouts/constants';

const useStyles = makeStyles()(
  theme => ({
    root: {
      height: 40,
      width: '100%',
      minWidth: DRAWER_WIDTH,
      display: 'flex',
      padding: theme.spacing(2, 4),
      color: theme.palette.text.secondary,
      alignItems: 'center',
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.palette.action.hover,
      },
      '&.isActive': {
        background: theme.palette.action.selected,
        color: theme.palette.primary.main,
      },
      '&.isClosed': {
        padding: theme.spacing(2),
      },
    },
    icon: {
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    external: {
      color: theme.palette.primary.main,
      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.08),
      },
    },
    externalIcon: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
  }),
  { name: 'bo-SidebarItem' }
);

export function SidebarItem({ href, icon: Icon, section, isOpen, isExternal, locale }) {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { classes, cx } = useStyles();
  const { asPath } = useRouter();

  const isActive = asPath.includes(href);

  const label = t(`sections.${section}`);

  return (
    <MuiTooltip
      // tooltip should only exist for desktop closed sidebar
      enterTouchDelay={0}
      leaveTouchDelay={15000}
      placement="right"
      title={!isDesktop || isOpen ? '' : label}
      arrow
      disableFocusListener>
      <div>
        <Link
          className={cx(
            classes.root,
            isExternal && classes.external,
            isActive && 'isActive',
            !isOpen && 'isClosed'
          )}
          data-test-id={section}
          href={href}
          locale={locale}
          prefetch={false}
          target={isExternal ? '_blank' : '_self'}
          passHref>
          <Icon className={classes.icon} />
          {label}
          {isExternal && (
            <div className={classes.externalIcon}>
              <ArrowForwardIcon color="primary" />
            </div>
          )}
        </Link>
      </div>
    </MuiTooltip>
  );
}

SidebarItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isExternal: PropTypes.bool,
  locale: PropTypes.string,
};

SidebarItem.defaultProps = {
  isExternal: false,
  locale: undefined,
};
