import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { LanguageFlag } from 'components/flag/language-flag';
import { useAppLanguage } from 'hooks/use-app-language';
import { makeStyles } from 'utils/make-styles';

import { LOCALES } from '../../../variables';

const useStyles = makeStyles()(
  theme => ({
    container: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(3),
      boxSizing: 'border-box',
    },
    languageLabel: {
      marginLeft: theme.spacing(3),
      textWrap: 'nowrap',
    },
    option: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
    },
  }),
  { name: 'bo-LanguageSelect' }
);

function HeaderLanguageSelectUI() {
  const { classes } = useStyles();
  const router = useRouter();
  const { locale } = router;

  const { setLanguage } = useAppLanguage();

  const onLocaleSelect = useCallback(event => setLanguage(event.target.value), [setLanguage]);

  return (
    <FormControl className={classes.container} size="small">
      <InputLabel id="language">
        <FormattedMessage id="header.menu.language" />
      </InputLabel>
      <Select
        defaultValue={locale}
        label={useMemo(
          () => (
            <FormattedMessage id="header.menu.language" />
          ),
          []
        )}
        labelId="language"
        onChange={onLocaleSelect}>
        {LOCALES.map(({ code, name }) => (
          <MenuItem value={code}>
            <Box className={classes.option}>
              <LanguageFlag code={code} />
              <Typography className={classes.languageLabel} variant="inherit" noWrap>
                {name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const HeaderLanguageSelect = memo(HeaderLanguageSelectUI);
