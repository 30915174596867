import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export function useTranslatedLanguageName() {
  const { locale } = useIntl();

  const languages = useMemo(() => new Intl.DisplayNames([locale], { type: 'language' }), [locale]);

  return useCallback(
    code => {
      let name;
      try {
        name = languages.of(code);
      } catch (e) {
        name = '';
      }

      return name;
    },
    [languages]
  );
}
