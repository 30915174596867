import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import { capitalizeFirst } from 'utils/string';

function AutocompleteInputUI({
  isLoading,
  placeholderId,
  InputLabelProps,
  InputProps,
  isEmptyValue,
  ...props
}) {
  const { formatMessage } = useIntl();

  return (
    <TextField
      {...props}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        notched: true,
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {InputProps?.endAdornment}
          </>
        ),
        ...InputProps,
      }}
      placeholder={
        isEmptyValue && placeholderId ? capitalizeFirst(formatMessage({ id: placeholderId })) : ''
      }
    />
  );
}

AutocompleteInputUI.propTypes = {
  isLoading: PropTypes.bool,
  isEmptyValue: PropTypes.bool,
  placeholderId: PropTypes.string,
  InputLabelProps: PropTypes.shape(),
  InputProps: PropTypes.shape(),
};

AutocompleteInputUI.defaultProps = {
  isLoading: false,
  isEmptyValue: false,
  placeholderId: undefined,
  InputLabelProps: undefined,
  InputProps: undefined,
};

export const AutocompleteInput = memo(AutocompleteInputUI);
