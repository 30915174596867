import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Flag } from 'components/flag/flag';
import { useTranslatedLanguageName } from 'hooks/use-translated-language-name';
import { LANGUAGE_ICONS_CDN } from 'utils/constants';

function LanguageFlagUI({ code }) {
  const translate = useTranslatedLanguageName();

  const flagUrl = LANGUAGE_ICONS_CDN.replace('{code}', code);

  return <Flag flagUrl={flagUrl} name={translate(code)} />;
}

LanguageFlagUI.propTypes = {
  code: PropTypes.string.isRequired,
};

export const LanguageFlag = memo(LanguageFlagUI);
