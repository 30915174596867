import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { useProjects } from 'api';
import { Autocomplete } from 'components/inputs/autocomplete';
import { useCurrentProject } from 'context/current-project';
import { RefType } from 'types';
import { SORT_BY } from 'utils/constants';

export function ProjectSearch({ inputRef, className }) {
  const [project, setProject] = useCurrentProject();
  const { data: projects, isFetching } = useProjects({
    initialParams: { limit: 100, sortBy: [SORT_BY.NAME.ASC] },
  });
  const handleChange = useCallback(
    projectKey => {
      setProject(projects.find(projectItem => projectItem.key === projectKey));
    },
    [projects, setProject]
  );

  return (
    <Autocomplete
      classes={{ root: className }}
      data-test-id="current-project"
      inputProps={{ inputRef }}
      labelKey="name"
      loading={isFetching}
      options={projects}
      sx={{ px: 3, py: 4, minWidth: 220 }}
      value={project?.key}
      valueKey="key"
      disableClearable
      openOnFocus
      onChange={handleChange}
    />
  );
}

ProjectSearch.propTypes = {
  inputRef: RefType.isRequired,
  className: PropTypes.string,
};

ProjectSearch.defaultProps = {
  className: '',
};
