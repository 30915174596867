import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useLogout } from 'api';
import { t } from 'components/formatted-message';
import { useUserProfile } from 'context/auth';
import { useMenuState } from 'hooks/use-menu-state';
import { useTimezone } from 'hooks/use-timezone';
import { getTimeZoneOffset } from 'utils/date';
import { makeStyles } from 'utils/make-styles';

import { HeaderLanguageSelect } from './header-language-select';

const useStyles = makeStyles()(
  theme => ({
    avatar: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundSize: 'cover',
      color: theme.palette.text.secondary,
    },
    menu: {
      width: 190,
    },
    name: {
      color: theme.palette.text.secondary,
      margin: `0 ${theme.spacing(2)}`,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }),
  { name: 'bo-Header' }
);

export function HeaderProfileDropdown() {
  const { classes } = useStyles();

  const { anchorEl, openMenu, closeMenu, isMenuOpen } = useMenuState();

  const { locale } = useIntl();
  const { userProfile } = useUserProfile();
  const timezone = useTimezone();
  const { mutate: logout } = useLogout();

  const handleLogoutClick = useCallback(() => {
    closeMenu();
    logout();
  }, [closeMenu, logout]);

  if (!userProfile) return null;

  return (
    <>
      <Button onClick={openMenu}>
        <AccountCircleIcon className={classes.avatar} />

        <Typography className={classes.name} component="div" variant="subtitle1">
          {`${userProfile.firstName} ${userProfile.lastName}`}
        </Typography>

        <ArrowDropDownIcon aria-expanded={isMenuOpen} fontSize="small" />
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ paper: classes.menu }}
        open={isMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closeMenu}>
        <MenuItem
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            pointerEvents: 'none',
            cursor: 'default',
          }}>
          <Typography color="textSecondary" variant="subtitle1">
            {`${userProfile.firstName} ${userProfile.lastName}`}
          </Typography>
          {timezone && (
            <Typography color="textSecondary" variant="caption">
              {timezone} ({getTimeZoneOffset(locale, timezone)})
            </Typography>
          )}
        </MenuItem>
        <HeaderLanguageSelect />
        <Divider />
        <MenuItem onClick={handleLogoutClick}>{t`header.menu.logout`}</MenuItem>
      </Menu>
    </>
  );
}
