import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';

import { Logo } from 'components/logo';
import { makeStyles } from 'utils/make-styles';

import { HeaderProfileDropdown } from './header-profile-dropdown';

const useStyles = makeStyles()(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    main: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 0,
      height: '100%',
      justifyContent: 'space-between',
    },
    left: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    right: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
    },
    menuToggle: {
      cursor: 'pointer',
      marginRight: theme.spacing(4),
    },
    center: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      '&.isMobile': {
        justifyContent: 'center',
      },
    },
    logo: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'bo-Header' }
);

export function Header({ toggleIsOpen, isDesktop }) {
  const { classes, cx } = useStyles();

  return (
    <header className={classes.root}>
      <div className={classes.main}>
        <div className={classes.left}>
          {!isDesktop && <MenuIcon className={classes.menuToggle} onClick={toggleIsOpen} />}
        </div>
        <div className={cx(classes.center, !isDesktop && 'isMobile')}>
          <Logo className={classes.logo} />
        </div>

        <div className={classes.right}>
          <HeaderProfileDropdown />
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};
